const companyRoutes = [
    {
      path: '/company',
      name: 'appCompany',
      meta: {requiresAuth: true,roles:['EMP']},
      component: ()=>import('@/layouts/AppLayout.vue'),
      beforeEnter: (to, from, next) => {
        document.body.classList.remove('login');
        next();
      },
      children: [
        {
          path: '',
          name: 'CompanyHome',
          component: ()=> import('@/views/Company/AppHome.vue'),
        },
        {
          path: 'inicio',
          name: 'companyStart',
          //component: ()=> import('@/views/Student/InitPractice/manageStar.vue'),
          children: [
            {
              path: '',
              name: 'companyInit',
              component: ()=> import('@/views/Company/InitPractice/companyStart.vue'),
            },
            
          ]
        },
        {
          path: 'configuracion',
          name: 'comiConfig',
          component: ()=>import('@/views/AppMantenimiento.vue'),
          children: [
            {
              path: 'perfil',
              component: ()=>import('@/components/Company/Configuration/AppProfile.vue')
            },
            {
              path: 'usuarios',
              component: ()=>import('@/components/Company/Configuration/AppUsers.vue')
            }
          ]
        }
      ],
    }
    // Otras rutas de decanatura ...
  ];
  
  export default companyRoutes;