import { createRouter, createWebHistory} from 'vue-router';

import store from '@/store/index.js';
import homeRoutes from './homeRoutes';
import adminRoutes from './adminRoutes';
import loginRoutes from './loginRoutes';
import decanaturaRoutes from './decanaturaRoutes';
import coordinatorRoutes from './coordinatorRoutes';
import studentRoutes from './studentRoutes';
import tutorRoutes from './tutorRoutes';
import docenteRoutes from './docenteRoutes';
import comissionRoutes from './comissionRoutes';
import companyRoutes from './companyRoutes';
import empresaRoutes from './empresaRoutes';
import immediatebossRoutes from './immediatebossRoutes';
import noFoundRoutes from './noFoundRoutes';
import root from '@/components/Utils/UtilDataMenu'

function hasPermission(roles, userRole) {
  return roles.includes(userRole);
}

function routeRoot(isoRole){
  return  '/'+root[isoRole].root;
}

const routes = [
  ...homeRoutes,
  ...loginRoutes,
  ...adminRoutes,
  ...coordinatorRoutes,
  ...docenteRoutes,
  ...tutorRoutes,
  ...studentRoutes,
  ...decanaturaRoutes,
  ...comissionRoutes,
  ...companyRoutes,
  ...empresaRoutes,
  ...immediatebossRoutes,
  ...noFoundRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



router.beforeEach((to, from, next) => {
 
  const authRequired = to.matched.some(record => record.meta.requiresAuth);
  const requiredRoles = to.meta.roles;
  const storeState = localStorage.getItem('store');

  
  if (!store.getters.getToken && storeState) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(storeState)));
  }

  if (to.path === '/login') {
    if (store.getters.isAuthenticated && !store.getters.hasCampus) {
      next('/select-campus');
    } else if(store.getters.isAuthenticated && store.getters.hasCampus) {
      console.log('redirige correctaebtew');
      next(routeRoot(store.getters.getCampus.actor.iso));
    }else{
      next();
    }
  } else if (!store.getters.isAuthenticated && authRequired) {
    next('/login');
  }else if (store.getters.isAuthenticated && requiredRoles && !hasPermission(requiredRoles, store.getters.getCampus.actor.iso)) {
    next('/unauthorized');
  } else if (store.getters.isAuthenticated && !store.getters.hasCampus && to.path !== '/select-campus') {
    next('/select-campus');
  } else {
    
    if (to.path.includes('/configuracion')) {
        store.dispatch('toggleSideBar', true);
    } else {
        store.dispatch('toggleSideBar', false);
    }
    next();
  }
});


export default router;