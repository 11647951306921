import store from '@/store/index.js';
import root from '@/components/Utils/UtilDataMenu'
const homeRoutes = [
  {
    path: '/',
    redirect: () => {
      // Realiza la lógica para determinar la ruta de redirección según el rol del usuario
      if(!store.getters.isAuthenticated){
        return '/login';
      }else if(store.getters.isAuthenticated && !store.getters.hasCampus){
        return '/select-campus';
      }else{
        return '/'+root[store.getters.getCampus.actor.iso].root;
      }
    },
  }
];

export default homeRoutes;