const tutorRoutes = [
  {
    path: '/tutor',
    name: 'appTutor',
    meta: {requiresAuth: true,roles:['TUTO']},
    component: ()=>import('@/layouts/AppLayout.vue'),
    beforeEnter: (to, from, next) => {
      document.body.classList.remove('login');
      next();
    },
    children: [
      {
        path: '',
        name: 'tutorHome',
        component: ()=> import('@/views/Tutor/AppHome.vue'),
      },
      {
        path: 'configuracion',
        name: 'tutorConfig',
        component: ()=>import('@/views/AppMantenimiento.vue'),
        children: [
          {
            path: 'perfil',
            component: ()=>import('@/components/Tutor/Configuration/AppProfile.vue')
          },
          {
            path: 'usuarios',
            component: ()=>import('@/components/Tutor/Configuration/AppUsers.vue')
          }
        ]
      }
    ],
  }
  // Otras rutas de tutoria...
];

export default tutorRoutes;