const empresaRoutes = [
  {
    path: '/empresa',
    name: 'appEmpresa',
    meta: {requiresAuth: true,roles:['EMP']},
    component: ()=>import('@/layouts/AppLayout.vue'),
    beforeEnter: (to, from, next) => {
      document.body.classList.remove('login');
      next();
    },
    children: [
      {
        path: '',
        name: 'BussHome',
        component: ()=> import('@/views/Empresa/AppHome.vue'),
      },
      {
        path: 'postulaciones',
        name: 'BussPostulations',
        //component: ()=> import('@/views/Student/InitPractice/manageStar.vue'),
        children: [
          {
            path: '',
            name: 'bussEstPostulaciones',
            component: ()=> import('@/views/Empresa/AppStudents.vue'),
          },
          {
            path: 'postulacion/:id',
            name: 'bussEstPostulation',
            component: ()=> import('@/views/Empresa/AppTimeline.vue'),
          },
          
        ]
      },
      {
        path: 'configuracion',
        name: 'decaConfig',
        component: ()=>import('@/views/AppMantenimiento.vue'),
        children: [
          {
            path: 'perfil',
            component: ()=>import('@/views/Empresa/AppProfile.vue')
          }
        ]
      }
    ],
  }
  // Otras rutas de Empresa ...
];

export default empresaRoutes;
