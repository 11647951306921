const noFoundRoutes = [
  {
    path: '/404',
    name: 'NotFound',
    component: ()=>import('@/views/NotFound.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
  // Otras rutas...
];

export default noFoundRoutes;