

import axios from 'axios';
import store from '@/store/index.js';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

api.interceptors.request.use(
  config => {
    const token = store.getters.getToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      const storeData = store.getters.getCampus;
      if (storeData) {
        config.headers['X-Requested-With'] = JSON.stringify(storeData);
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response && error.response.status === 403) {
      store.dispatch('logout');
    }

    if (error.response && error.response.status === 401 && error.response.data.message === 'UNAUTHORIZED_ROUTE') {
      store.dispatch('logout');
    }
    return Promise.reject(error);
  }
);

export default api;
