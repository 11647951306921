const menus = {
  ADMI: {
    root:'admin',
    sideBar: [
      { 
        name: 'practicante', 
        route: '#', 
        icono: 'fas fa-user-graduate',
        child: [
          { name: 'Gestionar practicantes', route: '/admin/practicantes' },
          { name: 'otro menu', route: '/admin/otro-menu' }
        ]
      },
      { 
        name: 'Docentes', 
        route: '#', 
        icono: 'fas fa-chalkboard-teacher',
        child: [
          { name: 'Gestionar docentes', route: '/admin/docentes' }
        ]
      },
      { 
        name: 'Tutores', 
        route: '#', 
        icono: 'fas fa-user-tie',
        child: [
          { name: 'Gestionar tutores', route: '/admin/tutores' }
        ]
      },
      { 
        name: 'Empresas', 
        route: '#', 
        icono: 'fas fa-building',
        child: [
          { name: 'Gestionar empresas', route: '/admin/empresas' }
        ]
      },
      { 
        name: 'Comision de prácticas', 
        route: '#', 
        icono: 'fas fa-users',
        child: [
          { name: 'Gestionar comisiones', route: '/admin/comision' }
        ]
      },
      { 
        name: 'Configuración', 
        route: '/admin/configuracion/perfil',
        icono: 'fas fa-cogs',
        child:[]
      }
    ],
    maintenance: [
      { 
        name: 'Perfil',
        route: '/admin/configuracion/perfil',
        icono: 'fas fa-user'
      },
      { 
        name: 'Usuarios',
        route: '/admin/configuracion/usuarios',
        icono: 'fas fa-users-cog'
      }
    ]
  },
  STD: {
    root:'estudiante',
    sideBar: [
      { 
        name: 'Inicio de prácticas', 
        route: '#', 
        icono: 'icon-arrow-right-circle',
        child: [
          { name: 'Gestionar inicio', route: '/estudiante/inicio' },
          { name: 'Plan de prácticas', route: '/estudiante/inicio/plan' },
          { name: 'Horario de prácticas', route: '/estudiante/inicio/horario'}
        ]
      },
      { 
        name: 'Gestion de prácticas',
        route: '#', 
        icono: 'icon-puzzle',
        child: [
          { name: 'Cronograma semanal', route: '/estudiante/cronograma' }
        ]
      },
      { 
        name: 'Configuración', 
        route: '/estudiante/configuracion/perfil',
        icono: 'fas fa-cogs',
        child:[]
      }
    ],
    maintenance: [
      { 
        name: 'Perfil',
        route: '/estudiante/configuracion/perfil',
        icono: 'fas fa-user'
      }
    ]
  },
  CORD: {
    root:'coordinador',
    sideBar: [
      { 
        name: 'Docentes', 
        route: '#', 
        icono: 'fas fa-chalkboard-teacher',
        child: [
          { name: 'Gestionar Docentes', route: '/coordinador/docentes' }
        ]
      },
      { 
        name: 'Estudiantes', 
        route: '#', 
        icono: 'fas fa-user-graduate',
        child: [
          { name: 'Gestionar estudiantes', route: '/coordinador/estudiantes' }
        ]
      },
      { 
        name: 'Comisión de prácticas', 
        route: '#', 
        icono: 'fas fa-users',
        child: [
          { name: 'Gestionar comisión', route: '/coordinador/comision' }
        ]
      },
      { 
        name: 'Empresa', 
        route: '#', 
        icono: 'fas fa-building',
        child: [
          { name: 'Gestionar Empresas', route: '/coordinador/empresas' },
          { name: 'Solicitud de registro', route: '/coordinador/empresa/solicitudes' }
        ]
      },
      { 
        name: 'Anexos', 
        route: '#', 
        icono: 'fas fa-file-signature',
        child: [
          { name: 'Anexos creados', route: '/coordinador/anexos' },
          { name: 'Crear Anexo', route: '/coordinador/anexos/create' }
        ]
      },
      { 
        name: 'Configuración', 
        route: '/coordinador/configuracion/perfil',
        icono: 'fas fa-cogs',
        child:[]
      }
    ],
    maintenance: [
      { 
        name: 'Perfil',
        route: '/coordinador/configuracion/perfil',
        icono: 'fas fa-user'
      },
      { 
        name: 'Usuarios',
        route: '/coordinador/configuracion/usuarios',
        icono: 'fas fa-users-cog'
      }
    ]
  },
  DOCE: {
    root:'docente',
    sideBar: [
      { 
        name: 'Estudiantes', 
        route: '#', 
        icono: 'fas fa-user-graduate',
        child: [
          { name: 'Gestionar estudiantes', route: '/docente/practicantes' }
        ]
      },
      { 
        name: 'Configuración', 
        route: '/docente/configuracion/perfil',
        icono: 'fas fa-cogs',
        child:[]
      }
    ],
    maintenance: [
      { 
        name: 'Perfil',
        route: '/docente/configuracion/perfil',
        icono: 'fas fa-user'
      }
    ]
  },
  TUTO: {
    root:'tutor',
    sideBar: [
      { 
        name: 'Tutorados', 
        route: '#', 
        icono: 'fas fa-user-graduate',
        child: [
          { name: 'Gestionar tutorados', route: '/tutor/practicantes' }
        ]
      },
      { 
        name: 'Configuración', 
        route: '/tutor/configuracion/perfil',
        icono: 'fas fa-cogs',
        child:[]
      }
    ],
    maintenance: [
      { 
        name: 'Perfil',
        route: '/tutor/configuracion/perfil',
        icono: 'fas fa-user'
      }
    ]
  },
  DECA: {
    root:'decanatura',
    sideBar: [
      { 
        name: 'Postulaciones', 
        route: '#', 
        icono: 'icon-arrow-right-circle',
        child: [
          { name: 'Estudiantes', route: '/decanatura/postulaciones' },
        ]
      },
      { 
        name: 'Configuración', 
        route: '/decanatura/configuracion/perfil',
        icono: 'fas fa-cogs',
        child:[]
      }
    ],
    maintenance: [
      { 
        name: 'Perfil',
        route: '/decanatura/configuracion/perfil',
        icono: 'fas fa-user'
      }
    ]
  },
  COMI: {
    root:'commission',
    sideBar: [
      { 
        name: 'Postulaciones', 
        route: '#', 
        icono: 'icon-arrow-right-circle',
        child: [
          { name: 'Estudiantes', route: '/commission/postulaciones' },
        ]
      },
      { 
        name: 'Configuración', 
        route: '/commission/configuracion/perfil',
        icono: 'fas fa-cogs',
        child:[]
      }
    ],
    maintenance: [
      { 
        name: 'Perfil',
        route: '/commission/configuracion/perfil',
        icono: 'fas fa-user'
      }
    ]
  },
  EMP: {
    root:'empresa',
    sideBar: [
      { 
        name: 'Postulaciones', 
        route: '#', 
        icono: 'icon-arrow-right-circle',
        child: [
          { name: 'Estudiantes', route: '/empresa/postulaciones' },
        ]
      },
      { 
        name: 'Configuración', 
        route: '/empresa/configuracion/perfil',
        icono: 'fas fa-cogs',
        child:[]
      }
    ],
    maintenance: [
      { 
        name: 'Perfil',
        route: '/empresa/configuracion/perfil',
        icono: 'fas fa-user'
      }
    ]
  },
  JEFE: {
    root:'immediateboss',
    sideBar: [
      { 
        name: 'Inicio', 
        route: '#', 
        icono: 'icon-arrow-right-circle',
        child: [
          { name: 'Gestionar inicio', route: '/immediateboss/inicio' }
        ]
      },
      { 
        name: 'Configuración', 
        route: '/immediateboss/configuracion/perfil',
        icono: 'fas fa-cogs',
        child:[]
      }
    ],
    maintenance: [
      { 
        name: 'Perfil',
        route: '/immediateboss/configuracion/perfil',
        icono: 'fas fa-user'
      }
    ]
  },
};

export default menus;