import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/atlantis.css'
import 'bootstrap/dist/js/bootstrap.js'
import $ from 'jquery'
import 'jquery-ui/dist/jquery-ui.js'

const app = createApp(App)
app.use(router)
app.use(store)
app.config.globalProperties.$jQuery = $
app.mount('#app')