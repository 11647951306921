const adminRoutes = [
  {
    path: '/admin',
    name: 'appAdmin',
    meta: {requiresAuth: true,roles:['ADMI']},
    component: ()=>import('@/layouts/AppLayout.vue'),
    beforeEnter: (to, from, next) => {
      document.body.classList.remove('login');
      next();
    },
    children: [
      {
        path: '',
        name: 'adminHome',
        component: ()=> import('@/views/Administration/AppHome.vue'),
      },
      {
        path: 'estudiantes',
        name: 'adminStudents',
        component: ()=> import('@/views/Administration/Interns/AppInterns.vue'),
      },
      {
        path: 'docentes',
        name: 'adminEducators',
        component: ()=> import('@/views/Administration/Educators/AppEducators.vue'),
      },
      {
        path: 'tutores',
        name: 'adminTutors',
        component: ()=>import('@/views/Administration/Tutors/AppTutors.vue'),
      },
      {
        path: 'comites',
        name: 'adminCommittee',
        component: ()=>import('@/views/Administration/Committee/AppCommittee.vue'),
      },
      {
        path: 'empresas',
        name: 'adminCompanies',
        component: ()=>import('@/views/Administration/Companies/AppCompanies.vue'),
      },
      {
        path: 'configuracion',
        name: 'adminConfiguration',
        component: ()=>import('@/views/AppMantenimiento.vue'),
        children: [
          {
            path: 'perfil',
            component: ()=>import('@/components/Administration/Configuration/AppProfile.vue')
          },
          {
            path: 'usuarios',
            component: ()=>import('@/components/Administration/Configuration/AppUsers.vue')
          }
        ]
      }
    ],
  }
  // Otras rutas de administración...
];

export default adminRoutes;