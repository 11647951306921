import api from '@/interceptors/apiInterceptor';
import router from '@/router/router'; // Importa tu instancia de Vue Router
export default{
    state: {
        token:null,
        user:null,
        sideBar:false,
        campus:{}
    },
    getters: {
        isAuthenticated(state){
            return state.token && state.user ? true : false;
        },
        hasCampus(state){
          return state.campus.actor && state.campus.campus.school ? true : false;
        },
        getUser(state){
            return state.user;
        },
        getSideBar(state){
            return state.sideBar;
        },
        getToken(state){
          return state.token;
        },
        getCampus(state){
          return state.campus;
        },
        getInfoCampus(state){
          const campusObject = state.campus.campus;
          const filteredCampus = state.user.campus.filter(item =>
            item.act_id === campusObject.actor &&
            item.fa_id === campusObject.faculty &&
            item.fas_id === campusObject.school &&
            item.cam_id === campusObject.campus
          );
          return filteredCampus.length > 0 ? filteredCampus[0] : null;
        }
       
    },
    mutations: {
        setUser(state, user){
            state.user = user;
        },
        setToken(state, token){
            state.token = token;
        },
        logout(state) {
            state.token = null;
            state.user = null;
            state.campus = {};
            state.sideBar=false;
        },
        setSideBar(state, sBar){
          state.sideBar = sBar;
        },
        setCampus(state, campus){
          state.campus = campus;
        }
       
    },
    actions: {
        async login({commit,dispatch}, credentials) {
          try {
            let res = await api.post(`/login`, credentials);
            res = res.data;
            if(!res.success) throw new Error(res.message);
            commit('setToken', res.data.token);
            return dispatch('attempt');
          } catch (error) {
            console.log(error);
          }
        },
      
        async attempt({ commit}) {
          try{
            const res = await api.get(`${process.env.VUE_APP_API_URL}/user`);
            commit('setUser', res.data.data);
            //asignamos valores a store campus
            const campus = {
              campus:{
                faculty:0,
                school:0,
                campus:0
              }
            };
            
            if(res.data.data.actor.length==1){
              campus.actor = res.data.data.actor[0];
              if(res.data.data.campus.length==1){
                campus.campus.actor = campus.actor.id;
                campus.campus.faculty = res.data.data.campus[0].fa_id;
                campus.campus.school = res.data.data.campus[0].fas_id;
                campus.campus.campus = res.data.data.campus[0].cam_id;
              }
            }

            commit('setCampus', campus);
            localStorage.setItem('store', JSON.stringify(this.state));
            return true;
          } catch (error) {
            commit('logout');
          }
        },
        logout({ commit }) {
          commit('logout');
          localStorage.removeItem('store');
          router.push('/login');
        },
        toggleSideBar({commit},val) {
          commit('setSideBar',val);
        },
        setCampus({commit},val) {
          commit('setCampus',val);
        },
        refreshState() { 
          localStorage.setItem('store', JSON.stringify(this.state));
        },
        
    }
}


