const immediatebossRoutes = [
    {
      path: '/immediateboss',
      name: 'appCompany',
      meta: {requiresAuth: true,roles:['JEFE']},
      component: ()=>import('@/layouts/AppLayout.vue'),
      beforeEnter: (to, from, next) => {
        document.body.classList.remove('login');
        next();
      },
      children: [
        {
          path: '',
          name: 'CompanyHome',
          component: ()=> import('@/views/ImmediateBoss/AppHome.vue'),
        },
        {
          path: 'inicio',
          name: 'companyStart',
          //component: ()=> import('@/views/Student/InitPractice/manageStar.vue'),
          children: [
            {
              path: '',
              name: 'immediatebossInit',
              component: ()=> import('@/views/ImmediateBoss/InitPractice/immbossStart.vue')
            },
            
          ]
        },
        {
          path: 'configuracion',
          name: 'comiConfig',
          component: ()=>import('@/views/AppMantenimiento.vue'),
          children: [
            {
              path: 'perfil',
              component: ()=>import('@/components/ImmediateBoss/Configuration/AppProfile.vue')
            },
            {
              path: 'usuarios',
              component: ()=>import('@/components/ImmediateBoss/Configuration/AppUsers.vue')
            }
          ]
        }
      ],
    }
    // Otras rutas de decanatura ...
  ];
  
  export default immediatebossRoutes;