const coordinatorRoutes = [
  {
    path: '/coordinador',
    name: 'appCoodinator',
    meta: {requiresAuth: true,roles:['CORD']},
    component: ()=>import('@/layouts/AppLayout.vue'),
    beforeEnter: (to, from, next) => {
      document.body.classList.remove('login');
      next();
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: ()=> import('@/views/Coordinator/AppHome.vue'),
      },
      {
        path: 'docentes',
        name: 'Teachers',
        component: ()=> import('@/components/Coordinator/AppDocentes.vue'),
      },
      {
        path: 'estudiantes',
        name: 'appStudents',
        component: ()=> import('@/components/Coordinator/AppStudents.vue'),
      },
      {
        path: 'comision',
        name: 'appCommission',
        component: ()=> import('@/components/Coordinator/AppComission.vue'),
      },
      {
        path: 'empresas',
        name: 'appEmpresa',
        children: [
          {
            path: '',
            name: 'appEmpresaList',
            component: ()=> import('@/views/Coordinator/AppEmpresa.vue'),
          },
          {
            path: 'config/:id',
            name: 'appEmpresaManagement',
            component: ()=>import('@/views/Coordinator/AppCogEmpresa.vue'),
            children: [
              {
                path: 'departamentos',
                component: ()=>import('@/components/Coordinator/AppEmpresaDepartaments.vue')
              },
              {
                path: 'jefes',
                component: ()=>import('@/components/Coordinator/AppEmpresaBoss.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'configuracion',
        name: 'appConfig',
        component: ()=>import('@/views/AppMantenimiento.vue'),
        children: [
          {
            path: 'perfil',
            component: ()=>import('@/components/Coordinator/Configuration/AppProfile.vue')
          },
          {
            path: 'usuarios',
            component: ()=>import('@/components/Coordinator/Configuration/AppUsers.vue')
          }
        ]
      }
    ],
  }
  // Otras rutas de administración...
];

export default coordinatorRoutes;