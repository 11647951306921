const comissionRoutes = [
    {
      path: '/commission',
      name: 'appComission',
      meta: {requiresAuth: true,roles:['COMI']},
      component: ()=>import('@/layouts/AppLayout.vue'),
      beforeEnter: (to, from, next) => {
        document.body.classList.remove('login');
        next();
      },
      children: [
        {
          path: '',
          name: 'ComiHome',
          component: ()=> import('@/views/Commission/AppHome.vue'),
        },
        {
          path: 'postulaciones',
          name: 'comiPostulations',
          //component: ()=> import('@/views/Student/InitPractice/manageStar.vue'),
          children: [
            {
              path: '',
              name: 'comissionPostulations',
              component: ()=> import('@/views/Commission/AppStudents.vue'),
            },
            {
              path: 'postulacion/:id',
              name: 'comiPostulation',
              component: ()=> import('@/views/Commission/AppTimeline.vue'),
            },
            
          ]
        },
        {
          path: 'configuracion',
          name: 'comiConfig',
          component: ()=>import('@/views/AppMantenimiento.vue'),
          children: [
            {
              path: 'perfil',
              component: ()=>import('@/components/Comission/Configuration/AppProfile.vue')
            },
            {
              path: 'usuarios',
              component: ()=>import('@/components/Comission/Configuration/AppUsers.vue')
            }
          ]
        }
      ],
    }
    // Otras rutas de decanatura ...
  ];
  
  export default comissionRoutes;