const decanaturaRoutes = [
  {
    path: '/decanatura',
    name: 'appDecanatura',
    meta: {requiresAuth: true,roles:['DECA']},
    component: ()=>import('@/layouts/AppLayout.vue'),
    beforeEnter: (to, from, next) => {
      document.body.classList.remove('login');
      next();
    },
    children: [
      {
        path: '',
        name: 'DecaHome',
        component: ()=> import('@/views/Decanatura/AppHome.vue'),
      },
      {
        path: 'postulaciones',
        name: 'decaPostulations',
        //component: ()=> import('@/views/Student/InitPractice/manageStar.vue'),
        children: [
          {
            path: '',
            name: 'estudiantePostulaciones',
            component: ()=> import('@/views/Decanatura/AppStudents.vue'),
          },
          {
            path: 'postulacion/:id',
            name: 'estudiantePostulation',
            component: ()=> import('@/views/Decanatura/AppTimeline.vue'),
          },
          
        ]
      },
      {
        path: 'configuracion',
        name: 'decaConfig',
        component: ()=>import('@/views/AppMantenimiento.vue'),
        children: [
          {
            path: 'perfil',
            component: ()=>import('@/components/Decanatura/Configuration/AppProfile.vue')
          },
          {
            path: 'usuarios',
            component: ()=>import('@/components/Decanatura/Configuration/AppUsers.vue')
          }
        ]
      }
    ],
  }
  // Otras rutas de decanatura ...
];

export default decanaturaRoutes;
