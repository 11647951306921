const studentRoutes = [
  {
    path: '/estudiante',
    name: 'appStudent',
    meta: {requiresAuth: true,roles:['STD']},
    component: ()=>import('@/layouts/AppLayout.vue'),
    beforeEnter: (to, from, next) => {
      document.body.classList.remove('login');
      next();
    },
    children: [
      {
        path: '',
        name: 'studentHome',
        component: ()=> import('@/views/Student/AppHome.vue'),
      },
      {
        path: 'inicio',
        name: 'manageStart',
        //component: ()=> import('@/views/Student/InitPractice/manageStar.vue'),
        children: [
          {
            path: '',
            name: 'manageInit',
            component: ()=> import('@/views/Student/InitPractice/manageStart.vue'),
          },
          {
            path: 'plan',
            name: 'managePlan',
            component: ()=> import('@/views/Student/InitPractice/managePlan.vue'),
          },
          {
            path: 'horario',
            name: 'manageShedule',
            component: ()=> import('@/views/Student/InitPractice/manageShedule.vue'),
          }
        ]
      },
      {
        path: 'configuracion',
        name: 'studentConfig',
        component: ()=>import('@/views/AppMantenimiento.vue'),
        children: [
          {
            path: 'perfil',
            component: ()=>import('@/components/Student/Configuration/AppProfile.vue')
          },
          {
            path: 'usuarios',
            component: ()=>import('@/components/Student/Configuration/AppUsers.vue')
          }
        ]
      }
    ],
  }
  // Otras rutas de estudiantes...
];

export default studentRoutes;