const loginRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: ()=> import('@/views/AppLogin.vue'),
    beforeEnter: (to, from, next) => {
      document.body.classList.add('login');
      next();
    },
  },
  {
    path: '/select-campus',
    name: 'SelectCampus',
    meta: {requiresAuth: true},
    component: ()=>import('@/views/SelectCampus.vue'),
  },
  // Otras rutas...
];

export default loginRoutes;