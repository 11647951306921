const tutorRoutes = [
  {
    path: '/docente',
    name: 'appDocente',
    meta: {requiresAuth: true,roles:['DOCE']},
    component: ()=>import('@/layouts/AppLayout.vue'),
    beforeEnter: (to, from, next) => {
      document.body.classList.remove('login');
      next();
    },
    children: [
      {
        path: '',
        name: 'educatorHome',
        component: ()=> import('@/views/Educator/AppHome.vue'),
      },
      {
        path: 'configuracion',
        name: 'educatorConfig',
        component: ()=>import('@/views/AppMantenimiento.vue'),
        children: [
          {
            path: 'perfil',
            component: ()=>import('@/components/Educator/Configuration/AppProfile.vue')
          }
        ]
      }
    ],
  }
  // Otras rutas de educators...
];

export default tutorRoutes;